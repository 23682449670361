/*!
 * Bootstrap v3.2.0 (http://getbootstrap.com)
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Generated using the Bootstrap Customizer (http://getbootstrap.com/customize/?id=03b181750697f7b70952)
 * Config saved to config.json and https://gist.github.com/03b181750697f7b70952
 */
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:active,
.btn.active {
    background-image: none;
}

.btn-default {
    border-color: #dbdbdb;
    border-color: #ccc;
}

.btn-default:hover,
.btn-default:focus {
    background-color: #e0e0e0;
}

.btn-default:active,
.btn-default.active {
    background-color: #e0e0e0;
    border-color: #dbdbdb;
}

.btn-default:disabled,
.btn-default[disabled] {
    background-color: #e0e0e0;
    background-image: none;
}

.btn-primary {
    border-color: #3a5467;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #3e596e;
    background-position: 0 -15px;
}

.btn-primary:active,
.btn-primary.active {
    background-color: #3e596e;
    border-color: #3a5467;
}

.btn-primary:disabled,
.btn-primary[disabled] {
    background-color: #3e596e;
    background-image: none;
}

.btn-success {
    border-color: #596724;
}

.btn-success:hover,
.btn-success:focus {
    background-color: #5f6f27;
    background-position: 0 -15px;
}

.btn-success:active,
.btn-success.active {
    background-color: #5f6f27;
    border-color: #596724;
}

.btn-success:disabled,
.btn-success[disabled] {
    background-color: #5f6f27;
    background-image: none;
}

.btn-info {
    border-color: #dbdbdb;
}

.btn-info:hover,
.btn-info:focus {
    background-color: #e0e0e0;
    background-position: 0 -15px;
}

.btn-info:active,
.btn-info.active {
    background-color: #e0e0e0;
    border-color: #dbdbdb;
}

.btn-info:disabled,
.btn-info[disabled] {
    background-color: #e0e0e0;
    background-image: none;
}

.btn-warning {
    border-color: #ff7d04;
}

.btn-warning:hover,
.btn-warning:focus {
    background-color: #ff820e;
    background-position: 0 -15px;
}

.btn-warning:active,
.btn-warning.active {
    background-color: #ff820e;
    border-color: #ff7d04;
}

.btn-warning:disabled,
.btn-warning[disabled] {
    background-color: #ff820e;
    background-image: none;
}

.btn-danger {
    border-color: #672b24;
}

.btn-danger:hover,
.btn-danger:focus {
    background-color: #6f2e27;
}

.btn-danger:active,
.btn-danger.active {
    background-color: #6f2e27;
    border-color: #672b24;
}

.btn-danger:disabled,
.btn-danger[disabled] {
    background-color: #6f2e27;
    background-image: none;
}

.thumbnail,
.img-thumbnail {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-image: -webkit-linear-gradient(top, #f2f2f2 0%, #e5e5e5 100%);
    background-image: -o-linear-gradient(top, #f2f2f2 0%, #e5e5e5 100%);
    background-image: linear-gradient(to bottom, #f2f2f2 0%, #e5e5e5 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
    background-color: #e5e5e5;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-image: -webkit-linear-gradient(top, #d1d1d1 0%, #c4c4c4 100%);
    background-image: -o-linear-gradient(top, #d1d1d1 0%, #c4c4c4 100%);
    background-image: linear-gradient(to bottom, #d1d1d1 0%, #c4c4c4 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd1d1d1', endColorstr='#ffc4c4c4', GradientType=0);
    background-color: #c4c4c4;
}

.navbar-default {
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
}

.navbar-default .navbar-nav > .active > a {
    background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f3f3f3 100%);
    background-image: -o-linear-gradient(top, #ebebeb 0%, #f3f3f3 100%);
    background-image: linear-gradient(to bottom, #ebebeb 0%, #f3f3f3 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff3f3f3', GradientType=0);
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
}

.navbar-brand,
.navbar-nav > li > a {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}

.navbar-inverse {
    background-image: -webkit-linear-gradient(top, #3c3c3c 0%, #222222 100%);
    background-image: -o-linear-gradient(top, #3c3c3c 0%, #222222 100%);
    background-image: linear-gradient(to bottom, #3c3c3c 0%, #222222 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

.navbar-inverse .navbar-nav > .active > a {
    background-image: -webkit-linear-gradient(top, #222222 0%, #282828 100%);
    background-image: -o-linear-gradient(top, #222222 0%, #282828 100%);
    background-image: linear-gradient(to bottom, #222222 0%, #282828 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff282828', GradientType=0);
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
    border-radius: 0;
}

.alert {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.alert-success {
    background-image: -webkit-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
    background-image: -o-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
    background-image: linear-gradient(to bottom, #dff0d8 0%, #c8e5bc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffc8e5bc', GradientType=0);
    border-color: #b2dba1;
}

.alert-info {
    background-image: -webkit-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
    background-image: -o-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
    background-image: linear-gradient(to bottom, #d9edf7 0%, #b9def0 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
    border-color: #9acfea;
}

.alert-warning {
    background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #f8efc0 100%);
    background-image: -o-linear-gradient(top, #fcf8e3 0%, #f8efc0 100%);
    background-image: linear-gradient(to bottom, #fcf8e3 0%, #f8efc0 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fff8efc0', GradientType=0);
    border-color: #f5e79e;
}

.alert-danger {
    background-image: -webkit-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: -o-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
    border-color: #dca7a7;
}

.progress {
    background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
    background-image: -o-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
    background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
}

.progress-bar {
    background-image: -webkit-linear-gradient(top, #547995 0%, #425f74 100%);
    background-image: -o-linear-gradient(top, #547995 0%, #425f74 100%);
    background-image: linear-gradient(to bottom, #547995 0%, #425f74 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff547995', endColorstr='#ff425f74', GradientType=0);
}

.progress-bar-success {
    background-image: -webkit-linear-gradient(top, #869c37 0%, #66762a 100%);
    background-image: -o-linear-gradient(top, #869c37 0%, #66762a 100%);
    background-image: linear-gradient(to bottom, #869c37 0%, #66762a 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff869c37', endColorstr='#ff66762a', GradientType=0);
}

.progress-bar-info {
    background-image: -webkit-linear-gradient(top, #91b4ce 0%, #6e9cbe 100%);
    background-image: -o-linear-gradient(top, #91b4ce 0%, #6e9cbe 100%);
    background-image: linear-gradient(to bottom, #91b4ce 0%, #6e9cbe 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff91b4ce', endColorstr='#ff6e9cbe', GradientType=0);
}

.progress-bar-warning {
    background-image: -webkit-linear-gradient(top, #ffa24b 0%, #ff8818 100%);
    background-image: -o-linear-gradient(top, #ffa24b 0%, #ff8818 100%);
    background-image: linear-gradient(to bottom, #ffa24b 0%, #ff8818 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffa24b', endColorstr='#ffff8818', GradientType=0);
}

.progress-bar-danger {
    background-image: -webkit-linear-gradient(top, #9c4137 0%, #76312a 100%);
    background-image: -o-linear-gradient(top, #9c4137 0%, #76312a 100%);
    background-image: linear-gradient(to bottom, #9c4137 0%, #76312a 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9c4137', endColorstr='#ff76312a', GradientType=0);
}

.progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.list-group {
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
    text-shadow: 0 -1px 0 #b8b8b8;
    background-image: -webkit-linear-gradient(top, #d1d1d1 0%, #bebebe 100%);
    background-image: -o-linear-gradient(top, #d1d1d1 0%, #bebebe 100%);
    background-image: linear-gradient(to bottom, #d1d1d1 0%, #bebebe 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd1d1d1', endColorstr='#ffbebebe', GradientType=0);
    border-color: #bebebe;
}

.panel {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.panel-default > .panel-heading {
    background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
    background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
    background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
}

.panel-primary > .panel-heading {
    background-image: -webkit-linear-gradient(top, #547995 0%, #4b6c85 100%);
    background-image: -o-linear-gradient(top, #547995 0%, #4b6c85 100%);
    background-image: linear-gradient(to bottom, #547995 0%, #4b6c85 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff547995', endColorstr='#ff4b6c85', GradientType=0);
}

.panel-success > .panel-heading {
    background-image: -webkit-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
    background-image: -o-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
    background-image: linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
}

.panel-info > .panel-heading {
    background-image: -webkit-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
    background-image: -o-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
    background-image: linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
}

.panel-warning > .panel-heading {
    background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
    background-image: -o-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
    background-image: linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
}

.panel-danger > .panel-heading {
    background-image: -webkit-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
    background-image: -o-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
    background-image: linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
}

.well {
    background-image: -webkit-linear-gradient(top, #e5e5e5 0%, #f2f2f2 100%);
    background-image: -o-linear-gradient(top, #e5e5e5 0%, #f2f2f2 100%);
    background-image: linear-gradient(to bottom, #e5e5e5 0%, #f2f2f2 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5', endColorstr='#fff2f2f2', GradientType=0);
    border-color: #d9d9d9;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
}